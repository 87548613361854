// period is stated in months
const custom_os_aop = {
	// 'ine_co_precvicenie': {'os':36,'aop':null},
	'k01_1b': {'os':12,'aop':null},
	'k01_1e': {'os':12,'aop':null},
	'zz_bd2': {'os':12,'aop':null},
	'opp_mimopracovne': {'os':12,'aop':null},
	// 'opp_poziare': {'os':24,'aop':null},
	'opp_hasiace': {'os':12,'aop':null},
	'ine_ppp': {'os':3,'aop':null},
	'ine_php': {'os':12,'aop':null},
}

const noPreukazAllowed = ["k01_1a","k01_1b","k01_1c","k01_1d","k01_1e","ine_co","opp_poziare","opp_mimopracovne", "ine_php"]

const periodOS_default = 36 // in months
const periodAOP_default = 60 // in months

export default custom_os_aop;
export {noPreukazAllowed,periodOS_default,periodAOP_default}
