import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
// import Switch, { Case, Default } from 'react-switch-case';
import Modal from 'react-bootstrap/Modal';
import { InputTags } from 'react-bootstrap-tagsinput'
import "./styles.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import SideBarItem from './sideBarItem.component';
import ZS from './formZS.hook';
import OS from './formOS.component';
import AOP from './formAOP.component';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Switch from 'react-ios-switch';



// import SingleAuto from './single_choice_auto.hook';
import Autocomplete_example from './autocomplete'

import { FaChevronDown,FaChevronUp } from "react-icons/fa";


import axios from 'axios';
import MultiSelect from "react-multi-select-component";

import {noPreukazAllowed} from '../controls/custom_os_aop.variable';


export default class CreatePersonalProfile extends Component {
  constructor(props) {
    super(props);
    
    this.createProfile = this.createProfile.bind(this);
    this.createLoading = this.createLoading.bind(this);
    this.createSuccess = this.createSuccess.bind(this);
    this.onChangeGetId = this.onChangeGetId.bind(this);
    this.changeCourses_short = this.changeCourses_short.bind(this);
    this.onChangePersonal = this.onChangePersonal.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onChangeUniversal = this.onChangeUniversal.bind(this);
    this.componentWillMount = this.componentWillMount.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.npreukazTracer = this.npreukazTracer.bind(this);
    this.final_checks = this.final_checks.bind(this);
    this.final_checks_clear = this.final_checks_clear.bind(this);
    this.onChangePreukazScan = this.onChangePreukazScan.bind(this);
    this.color = "white";
    this.preserveState = this.preserveState.bind(this);
    this.ref = React.createRef(null);
    

    this.state = {
      // ref: React.createRef(),
      noPreukazAllowed: noPreukazAllowed,
      dict: {
        zakladne: {
          skuska: "",
          vydanie: null,
          evcislo: null,
          rozsah: null,
          rozsahArr: null,
        }, 
        opakovane: {
          skuska0: null,
          skuska1: null,
          skuska2: null,
          skuska3: null,
          skuska4: null,
          skuska5: null,
          predAOP0: null,
          predAOP1: null,
          predAOP2: null,
          predAOP3: null,
          predAOP4: null,
          predAOP5: null,
          predAOParr0: [],
          predAOParr1: [],
          predAOParr2: [],
          predAOParr3: [],
          predAOParr4: [],
          predAOParr5: [],
          error: null,
          poznamka: null,
        }, 
        aktualizacia: {
          skuska0: null,
          skuska1: null,
          skuska2: null,
          skuska3: null,
          skuska4: null,
          skuska5: null,
          predAOP0: null,
          predAOP1: null,
          predAOP2: null,
          predAOP3: null,
          predAOP4: null,
          predAOP5: null,
          predAOParr0: [],
          predAOParr1: [],
          predAOParr2: [],
          predAOParr3: [],
          predAOParr4: [],
          predAOParr5: [],
          error: null,
          poznamka: null,
        },
        npreukazState: 0,
        active: true,
      },

      npreukazToUpload: 0, //to check number of uploaded licence scans
      showCreatePopup: false,
      showDataCheckPopup: false,
      showPersonDefinedPopup: false,
      showDeleteCoursePopup: false,
      showSpinner: false,
      currentTutorial: {
        active: true,
        personal:{
          meno: null,
          priezvisko: null,
          titul: null,
          ulica: null,
          PSC: null,
          mesto: null,
          email: null,
          mobil: null,
          "dátum narodenia": null,
          "zamestnávateľ": null,
          pobocka:null,
          poznamka: null,
          profesia: null,
        },
      },
      message: "",
      createMessage: "",
      uploadMessage: "",
      error: "",
      showCourses: {},
      others: {
        showComment: false
      },
      sidebar: "caste",
      fotka: "",
      formData: new FormData(),
      checks: {}
    };
  }

  onChangePreukazScan = (e,course_nazov,id_preukaz,provided = null) => {
    const value = (provided==='provided' ? e : e.target.files[0])
    console.log('provided, value:',provided,value)
    console.log("setting",["preukaz"+id_preukaz], [value])
    // if (e.target && e.target.files[0]) {
      this.setState(function(prevState) {
        return {
          currentTutorial: {
            ...prevState.currentTutorial,
            [course_nazov]: {
              ...prevState.currentTutorial[course_nazov],
              ["preukaz"+id_preukaz]: value
            }
          }
        };
      })
    // }
  }

  preserveState(){
    console.log('in preserve state')
    window.sessionStorage.setItem("createPersonalProfileState",JSON.stringify(this.state))
    window.location.href="/add_company"
    // navidate('/add_company')
    // this.props.history.push('/add_company')
  }

  final_checks(){
    //non-empty name
    let ok = true;
    if (!this.state.currentTutorial.personal.meno || this.state.currentTutorial.personal.meno==="") {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_name": "Meno nezadané!"}}})
      ok=false
    }

    //non-empty surname
    if (!this.state.currentTutorial.personal.priezvisko || this.state.currentTutorial.personal.priezvisko==="") {
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_surname": "Priezvisko nezadané!"}}})
      ok=false
    }

    //non-empty date of birth
    if (!this.state.currentTutorial.personal["dátum narodenia"] || this.state.currentTutorial.personal["dátum narodenia"]==="") {
      console.log('datum narodenia null alebo ""')
      this.setState(function(prevState) {return {checks: {...prevState.checks,"error_birth": "Dátum narodenia nezadaný!"}}})
      ok=false
    }
    //correct format of date of birth
    else{
      if (!this.isDate(this.state.currentTutorial.personal["dátum narodenia"])) {
        this.setState(function(prevState) {return {checks: {...prevState.checks,"error_birth": "Zlý formát, zadaj ako DD.MM.RRRR"}}})
        ok=false
      }
    }

    //is person already in the database?
    const table_person = this.state.database.filter(item=>item.table_name === 'personal_details')[0]
    const person_already_defined = table_person.data.filter(person=>
      person.meno === this.state.currentTutorial.personal.meno && person.priezvisko === this.state.currentTutorial.personal.priezvisko && person["dátum narodenia"] === this.state.currentTutorial.personal["dátum narodenia"]
    )
    if (person_already_defined && person_already_defined.length>0){
      console.log('person matched:',person_already_defined)
      this.setState({showPersonDefinedPopup: true})
      ok = false
    }

    //check all courses
    this.state.allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(course =>{
      //if npreukaz=0, depends on datum skolenia, poznamka, opakovaneArr, aktualizaciaArr 
      if ( this.state.currentTutorial[course.course_nazov].npreukazState ===0) {
        if (
        (!this.state.currentTutorial[course.course_nazov].zakladne.skuska || this.state.currentTutorial[course.course_nazov].zakladne.skuska==="")
        && (!this.state.currentTutorial[course.course_nazov].zakladne.poznamka || this.state.currentTutorial[course.course_nazov].zakladne.poznamka==="")      
        // && (!this.state.currentTutorial[course.course_nazov].opakovane.predAOP0 || this.state.currentTutorial[course.course_nazov].opakovane.predAOP0==="")
        // && (!this.state.currentTutorial[course.course_nazov].aktualizacia.predAOP0 || this.state.currentTutorial[course.course_nazov].aktualizacia.predAOP0==="")      
        && this.state.currentTutorial[course.course_nazov].opakovane.predAOParr0.length===0
        &&  this.state.currentTutorial[course.course_nazov].aktualizacia.predAOParr0.length===0    
        ) 
        {
        console.log('empty course:',course.course_nazov)
        this.final_checks_clear("error_"+course.course_nazov)
        }else{
        //non-empty datum zakladneho skolenia
        if (!this.state.currentTutorial[course.course_nazov].zakladne.skuska || this.state.currentTutorial[course.course_nazov].zakladne.skuska==="") {
          console.log('datum zakladnej skusky null alebo ""')
          this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+course.course_nazov]: "Dátum základného školenia nezadaný!"}}})
          ok=false
        }
        //correct format of datum zakladneho skolenia
        else{
          if (!this.isDate(this.state.currentTutorial[course.course_nazov].zakladne.skuska)) {
            this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+course.course_nazov]: "Zlý formát, zadaj ako DD.MM.RRRR"}}})
            ok=false
          }
        }

      }
    }else{

    //if npreukaz>0, depends on  
      if ((!this.state.currentTutorial[course.course_nazov].zakladne.skuska || this.state.currentTutorial[course.course_nazov].zakladne.skuska==="")
      && (!this.state.currentTutorial[course.course_nazov].zakladne.poznamka || this.state.currentTutorial[course.course_nazov].zakladne.poznamka==="")      
      && (!this.state.currentTutorial[course.course_nazov].zakladne.vydanie || this.state.currentTutorial[course.course_nazov].zakladne.vydanie==="" || this.state.currentTutorial[course.course_nazov].zakladne.vydanie===";;")
      && (!this.state.currentTutorial[course.course_nazov].zakladne.evcislo || this.state.currentTutorial[course.course_nazov].zakladne.evcislo==="" || this.state.currentTutorial[course.course_nazov].zakladne.evcislo===";;")      
      && this.state.currentTutorial[course.course_nazov].opakovane.predAOParr1.length===0
      && this.state.currentTutorial[course.course_nazov].aktualizacia.predAOParr1.length===0
      && this.state.currentTutorial[course.course_nazov].opakovane.predAOParr2.length===0
      && this.state.currentTutorial[course.course_nazov].aktualizacia.predAOParr2.length===0
      && this.state.currentTutorial[course.course_nazov].opakovane.predAOParr3.length===0
      && this.state.currentTutorial[course.course_nazov].aktualizacia.predAOParr3.length===0
      && this.state.currentTutorial[course.course_nazov].opakovane.predAOParr4.length===0
      && this.state.currentTutorial[course.course_nazov].aktualizacia.predAOParr4.length===0
      && this.state.currentTutorial[course.course_nazov].opakovane.predAOParr5.length===0
      && this.state.currentTutorial[course.course_nazov].aktualizacia.predAOParr5.length===0
    ) {
      console.log('empty course:',course.course_nazov)
      this.final_checks_clear("error_"+course.course_nazov)
    }else{
      //non-empty datum zakladneho skolenia
      if (!this.state.currentTutorial[course.course_nazov].zakladne.skuska || this.state.currentTutorial[course.course_nazov].zakladne.skuska==="") {
        console.log('datum zakladnej skusky null alebo "" for ',course.course_nazov)
        this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+course.course_nazov]: "Dátum základného školenia nezadaný!"}}})
        ok=false
      }
      //correct format of datum zakladneho skolenia
      else{
        if (!this.isDate(this.state.currentTutorial[course.course_nazov].zakladne.skuska)) {
          this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+course.course_nazov]: "Zlý formát, zadaj ako DD.MM.RRRR"}}})
          ok=false
        }
      }

      // license number were not given
      let evcislaLocal = []
      this.state.currentTutorial[course.course_nazov].zakladne.evcislo.split(";;").map( (cislo,index) => {
        console.log(`cislo ${index+1}:`,cislo)
        if (cislo==="") {
          console.log(`evcislo ${index+1} ""`)
          this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+course.course_nazov+"_evcislo"+(index+1)]: "Ev. číslo preukazu "+(index+1)+ " nezadaný!"}}})
          ok=false
        }
        // if (this.state.allEvcisla.includes(cislo) || evcislaLocal.includes(cislo) ){
        if (this.state.allEvcisla.includes(cislo)){
          this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+course.course_nazov+"_evcislo"+(index+1)]: "Ev. číslo preukazu "+(index+1)+ " už bolo použité!"}}})
          ok = false
        }
        evcislaLocal.push(cislo)
      })

      // format vydania preukazu
      this.state.currentTutorial[course.course_nazov].zakladne.vydanie.split(";;").map( (date,index) => {
        console.log(`datum vydania ${index+1}:`,date)
        if (date==="") {
          console.log(`datum vydania ${index+1} ""`)
          this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+course.course_nazov+"_vydanie"+(index+1)]: "Dátum vydania preukazu "+(index+1)+ " nezadaný!"}}})
          ok=false
        }else{
          if (!this.isDate(date)) {
            this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+course.course_nazov+"_vydanie"+(index+1)]: "Zlý formát dátumu vydania preukazu "+(index+1)+", zadaj ako DD.MM.RRRR"}}})
            ok=false
          }
        }
      })

      //check if rozsahy set (not left empty) - this check is valid for npreukaz_max = 1 !!!
      if (this.state.allCourses.filter(item=>item.course_nazov===course.course_nazov)[0].course_rozsah){
        let empty = true
        if (!this.state.currentTutorial[course.course_nazov].zakladne.rozsah){
          empty = true
        }else{
          empty = true
          Object.keys(this.state.currentTutorial[course.course_nazov].zakladne.rozsah).forEach(key=>{
            if (this.state.currentTutorial[course.course_nazov].zakladne.rozsah[key].length!=0) empty = false
          })
        }
        if (empty === true) {
          this.setState(function(prevState) {return {checks: {...prevState.checks,["error_"+course.course_nazov+"_rozsah"]: "Rozsah preukazu 1 nezadaný"}}})
          ok = false
        }
      }

    }

    }

    })
    
    return ok;
  }

  final_checks_clear(err) {
    this.setState(function(prevState) {return {checks: {...prevState.checks,[err]: ""}}})
  }

  npreukazTracer(course_name,npreukaz) {
    console.log('nPreukazTracer:',course_name,npreukaz,this.state.currentTutorial[course_name])
    this.setState(function(prevState) {
      return {
        currentTutorial: {
          ...prevState.currentTutorial,
          [course_name]: {
            ...prevState.currentTutorial[course_name],
            npreukazState: npreukaz
          }
        }
      };
    })
  }

  componentWillMount() {
    console.log('in WillMount-getAllCourses');
    
    TutorialDataService.getAllCompanies()
      .then(response => {
        console.log("received companies",response.data);

        this.setState({
          allCompanies: response.data[0]
        });

      })
      .catch(e => {
        console.log('The allCompanies fetch failed!',e);
      });
      
    if (window.sessionStorage.getItem("createPersonalProfileState")) {
      console.log("coming back")
      var assigned = 0
      var fun = 0
      if  (fun===0) {
        this.state = JSON.parse(window.sessionStorage.getItem("createPersonalProfileState"))
        console.log('this state:',this.state)
        assigned = 1
        fun = 1
      }

      console.log('assigned:',assigned)
      if (assigned === 1) {console.log('removing stored personal profile state...');window.sessionStorage.removeItem("createPersonalProfileState")}
    }
    else{

    TutorialDataService.getAllCourses()
      .then(response => {
        console.log("received data",response.data);
        this.setState({
          message: "The courses were obtained successfully!"
        });
        this.setState({
          allCourses: response.data[0]
        });

        response.data[0].map(course => {
          if (!([course.course_nazov] in this.state.showCourses)) {
            this.setState(function(prevState) {
              return {
                showCourses: {
                  ...prevState.showCourses,
                  [course]: false
                }
              };
            })
            // console.log('initializing showCourses')
          }});

        response.data[0].map(course => {
          if (!([course.course_nazov] in this.state.currentTutorial)) {
            this.setState(function(prevState) {
              return {
                currentTutorial: {
                  ...prevState.currentTutorial,
                  [course.course_nazov]: this.state.dict
                }
              };
            })
            // console.log('initializing current tutorials')
        }})
      })
      .catch(e => {
        console.log('The courses fetch failed!',e);
      });
    }

    TutorialDataService.getWholeDatabase()
      .then(response => {
        // this.setState({database: response.data},function() {this.build_tree()})
        this.setState({database: response.data})
        console.log("received full database data",response.data);
      })
      .then(()=>{
        console.log('getting all license numbers...')
        let evcislaAll = []
        let evcislaDict = {}
        this.state.database && this.state.allCourses.map(course =>{
          const table  = this.state.database.filter(tab => tab.table_name===course.course_alias+"s")[0]
          // console.log('zevcisla in table:',table)
          table && table.data.map(table_entry=>{
            // console.log('zevcislo to split:',table_entry[course.course_alias+ "_zevcislo"])
            const zevcisla = (table_entry[course.course_alias+ "_zevcislo"])?table_entry[course.course_alias+ "_zevcislo"].split(";;"):[]
            // console.log('current evcisla:',zevcisla)
            if (zevcisla.length>0){
              evcislaAll = evcislaAll.concat(zevcisla)
              //store more data into dictionary
              zevcisla.map(evcislo=>{
                if (!(evcislo in evcislaDict)){
                  evcislaDict[evcislo] = {[table_entry[course.course_alias+ "_id"]]: [course.course_nazov]}
                }else{
                  if (table_entry[course.course_alias+ "_id"] in evcislaDict[evcislo]){
                    evcislaDict[evcislo][table_entry[course.course_alias+ "_id"]].push(course.course_nazov)
                  }else{
                    evcislaDict[evcislo][table_entry[course.course_alias+ "_id"]] = [course.course_nazov]
                  }
                }
              })
            }
          })
          // console.log('after this table, zevcisla: ',evcislaAll)
        })
        this.setState({allEvcisla: evcislaAll,evcislaDict: evcislaDict})
      })
      .catch(e => {
        console.log('The full data fetch failed!',e);
      });



  }

  handleKeyDown = (evt,profesia,skolenie,npreukaz) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
        
      var entry = this.state.currentTutorial[profesia][skolenie]["predAOP"+npreukaz].trim();
      console.log('entry:',entry)
        
      if (entry && this.isValid(entry,profesia,skolenie,npreukaz)) {
          this.setState(function(prevState) {
            return {
              currentTutorial: {
                ...prevState.currentTutorial,
                [profesia]: {
                  ...prevState.currentTutorial[profesia],
                  [skolenie]: {
                    ...prevState.currentTutorial[profesia][skolenie],
                    ['predAOParr'+npreukaz]: [...this.state.currentTutorial[profesia][skolenie]['predAOParr'+npreukaz], entry],
                    ["predAOP"+npreukaz]: ""
                  }
                }
              }
            }
          })
        console.log('array',this.state.currentTutorial[profesia][skolenie].predAOParr)
        console.log(`predAOP${npreukaz}`,this.state.currentTutorial[profesia][skolenie]["predAOP"+npreukaz])
      }
    }
  }

  isValid(date,profesia,skolenie,npreukaz) {
    let error = null;

    if (this.isInList(date,profesia,skolenie,npreukaz)) {
      error = `Dátum ${date} už bol pridaný.`;
    }

    if (!this.isDate(date)) {
      error = `${date} nie je platný formát dátumu.`;
    }

    if (error) {
      this.setState(function(prevState) {
        return {
          currentTutorial: {
            ...prevState.currentTutorial,
            [profesia]: {
              ...prevState.currentTutorial[profesia],
              [skolenie]: {
                ...prevState.currentTutorial[profesia][skolenie],
                error: error,
              }
            }
          }
        }
      })

      return false;
    }

    return true;
  }

  isInList(date,profesia,skolenie,npreukaz) {
    console.log('profesia, skolenie, npreukaz: ',profesia,skolenie,npreukaz)
    return this.state.currentTutorial[profesia][skolenie]['predAOParr'+npreukaz].includes(date);
  }

  isDate(date) {
    return /^(\d{2})(\.)(\d{2})(\.)(\d{4})$/.test(date);
  }
  handlePaste = (evt,profesia,skolenie,npreukaz) => {
    evt.preventDefault();
    console.log('handlePaste profesia, skolenie, npreukaz:',profesia,skolenie, npreukaz)
    var paste = evt.clipboardData.getData("text");
    var dates = paste.match(/^(\d{2})(\.)(\d{2})(\.)(\d{4})$/g);

    if (dates) {
      var toBeAdded = dates.filter(date => !this.isInList(date,profesia,skolenie,npreukaz));
        this.setState(function(prevState) {
          return {
            currentTutorial: {
              ...prevState.currentTutorial,
              [profesia]: {
                ...prevState.currentTutorial[profesia],
                [skolenie]: {
                  ...prevState.currentTutorial[profesia][skolenie],
                  ['predAOParr'+npreukaz]: [...this.state.currentTutorial[profesia][skolenie]['predAOParr'+npreukaz], ...toBeAdded],
                }
              }
            }
          }
        })
    }
  };

  handleDelete = (item,profesia,skolenie,npreukaz) => {
      this.setState(function(prevState) {
        return {
          currentTutorial: {
            ...prevState.currentTutorial,
            [profesia]: {
              ...prevState.currentTutorial[profesia],
              [skolenie]: {
                ...prevState.currentTutorial[profesia][skolenie],
                ['predAOParr'+npreukaz]:  this.state.currentTutorial[profesia][skolenie]['predAOParr'+npreukaz].filter(i => i !== item)
              }
            }
          }
        }
      })
  };

  onChangeUniversal(e,profesia,skolenie,vstup,direct='no'){
    const entry = (direct==='yes') ? e : e.target.value;
    console.log('what:',[profesia],skolenie,vstup)
    console.log('entry,e:',entry,e)
    console.log('this.state.currentTutorial',this.state.currentTutorial)
    console.log('this.state.currentTutorial[profesia]',this.state.currentTutorial[profesia])
    {vstup.substring(0,vstup.length - 1)==='predAOP' ? (
    this.setState(function(prevState) {
      return {
        currentTutorial: {
          ...prevState.currentTutorial,
          [profesia]: {
            ...prevState.currentTutorial[profesia],
            [skolenie]: {
              ...prevState.currentTutorial[profesia][skolenie],
              [vstup]: entry,
              error: ""
            }
          }
        }
      };
    }) 
    ) : (
    this.setState(function(prevState) {
      return {
        currentTutorial: {
          ...prevState.currentTutorial,
          [profesia]: {
            ...prevState.currentTutorial[profesia],
            [skolenie]: {
              ...prevState.currentTutorial[profesia][skolenie],
              [vstup]: entry
            }
          }
        }
      };
    }) 

    )}
  }

  changeCourses_short(course) {
    console.log('course:',course)
    if (this.course_is_clear(course)===true){
      return(
      this.setState(function(prevState) {
        return {
          showCourses: {
            ...prevState.showCourses,
            [course]: !this.state.showCourses[course]
          }
        };
      }))
    }else{
      return(
        this.setState(function(prevState) {
          return {
            showDeleteCoursePopup: true,
            actual_course: course
          };
        }))
    }
  }

  changeCourses_short_delete(){
    return(
      this.setState(function(prevState) {
        return {
          showCourses: {
            ...prevState.showCourses,
            [this.state.actual_course]: !this.state.showCourses[this.state.actual_course]
          },
          currentTutorial: {
            ...prevState.currentTutorial,
            [this.state.actual_course]: this.state.dict
          },
          ['show_'+this.state.actual_course]:null,
        };
      }))
  }

  course_is_clear(course_nazov){
    // returns true if course data are empty
    // returns false if course data are not empty
    if ( this.state.currentTutorial[course_nazov].npreukazState ===0) {
      //if npreukaz==0, depends on  
      if (
      (!this.state.currentTutorial[course_nazov].zakladne.skuska || this.state.currentTutorial[course_nazov].zakladne.skuska==="")
      && (!this.state.currentTutorial[course_nazov].zakladne.poznamka || this.state.currentTutorial[course_nazov].zakladne.poznamka==="")      
      && this.state.currentTutorial[course_nazov].opakovane.predAOParr0.length===0
      &&  this.state.currentTutorial[course_nazov].aktualizacia.predAOParr0.length===0    
      ) 
      {
      console.log('empty course:',course_nazov)
      return true
      }else{
        console.log('non-empty course',course_nazov)
        return false
      }
    }else{
      //if npreukaz>0, depends on  
      if ((!this.state.currentTutorial[course_nazov].zakladne.skuska || this.state.currentTutorial[course_nazov].zakladne.skuska==="")
        && (!this.state.currentTutorial[course_nazov].zakladne.poznamka || this.state.currentTutorial[course_nazov].zakladne.poznamka==="")      
        && (!this.state.currentTutorial[course_nazov].zakladne.vydanie || this.state.currentTutorial[course_nazov].zakladne.vydanie==="" || this.state.currentTutorial[course_nazov].zakladne.vydanie===";;")
        && (!this.state.currentTutorial[course_nazov].zakladne.evcislo || this.state.currentTutorial[course_nazov].zakladne.evcislo==="" || this.state.currentTutorial[course_nazov].zakladne.evcislo===";;")      
        && this.state.currentTutorial[course_nazov].opakovane.predAOParr1.length===0
        && this.state.currentTutorial[course_nazov].aktualizacia.predAOParr1.length===0
        && this.state.currentTutorial[course_nazov].opakovane.predAOParr2.length===0
        && this.state.currentTutorial[course_nazov].aktualizacia.predAOParr2.length===0
        && this.state.currentTutorial[course_nazov].opakovane.predAOParr3.length===0
        && this.state.currentTutorial[course_nazov].aktualizacia.predAOParr3.length===0
        && this.state.currentTutorial[course_nazov].opakovane.predAOParr4.length===0
        && this.state.currentTutorial[course_nazov].aktualizacia.predAOParr4.length===0
        && this.state.currentTutorial[course_nazov].opakovane.predAOParr5.length===0
        && this.state.currentTutorial[course_nazov].aktualizacia.predAOParr5.length===0
      ) {
        console.log('empty course:',course_nazov)
        return true
      }else{
        console.log('non-empty course:',course_nazov)
        return false
      }
    }
  }

  onChangePersonal(e,prop,direct = null) {
    console.log('e:',(direct)?e:e.target.value);
    console.log('prop:',prop);
    const entry = (direct)?e:e.target.value;

    switch(prop){
      case 'titul':
          console.log('case titul');
          return(
          this.setState(function(prevState) {
            return {
              currentTutorial: {
                ...prevState.currentTutorial,
                personal: {
                  ...prevState.currentTutorial.personal,
                  titul: entry
                }
              }
            };
          })) 
      case 'meno':
          console.log('case meno');
          return(
          this.setState(function(prevState) {
            return {
              currentTutorial: {
                ...prevState.currentTutorial,
                personal: {
                  ...prevState.currentTutorial.personal,
                  meno: entry
                }
              }
            };
          }))      
      case 'priezvisko':
          console.log('case priezvisko');
          return(
          this.setState(function(prevState) {
            return {
              currentTutorial: {
                ...prevState.currentTutorial,
                personal: {
                  ...prevState.currentTutorial.personal,
                  priezvisko: entry
                }
              }
            };
          }))
      case 'ulica':
          console.log('case ulica');
          return(
          this.setState(function(prevState) {
            return {
              currentTutorial: {
                ...prevState.currentTutorial,
                personal: {
                  ...prevState.currentTutorial.personal,
                  ulica: entry
                }
              }
            };
          }))
      case 'PSC':
          console.log('case PSC');
          return(
          this.setState(function(prevState) {
            return {
              currentTutorial: {
                ...prevState.currentTutorial,
                personal: {
                  ...prevState.currentTutorial.personal,
                  PSC: entry
                }
              }
            };
          }))
      case 'mesto':
          console.log('case mesto');
          return(
          this.setState(function(prevState) {
            return {
              currentTutorial: {
                ...prevState.currentTutorial,
                personal: {
                  ...prevState.currentTutorial.personal,
                  mesto: entry
                }
              }
            };
          }))
      case 'email':
        console.log('case email');
        return(
        this.setState(function(prevState) {
          return {
            currentTutorial: {
              ...prevState.currentTutorial,
              personal: {
                ...prevState.currentTutorial.personal,
                email: entry
              }
            }
          };
        }))
      case 'mobil':
        console.log('case mobil');
        return(
        this.setState(function(prevState) {
          return {
            currentTutorial: {
              ...prevState.currentTutorial,
              personal: {
                ...prevState.currentTutorial.personal,
                mobil: entry
              }
            }
          };
        })) 
      case 'dátum narodenia':
        console.log('case dátum narodenia');
        return(
        this.setState(function(prevState) {
          return {
            currentTutorial: {
              ...prevState.currentTutorial,
              personal: {
                ...prevState.currentTutorial.personal,
                "dátum narodenia": entry
              }
            }
          };
        }))     
      case 'zamestnávateľ':
        console.log('case zamestnávateľ');
        return(
        this.setState(function(prevState) {
          return {
            currentTutorial: {
              ...prevState.currentTutorial,
              personal: {
                ...prevState.currentTutorial.personal,
                "zamestnávateľ": entry
              }
            }
          };
        }))
        case 'pobocka':
          console.log('case pobocka');
          return(
          this.setState(function(prevState) {
            return {
              currentTutorial: {
                ...prevState.currentTutorial,
                personal: {
                  ...prevState.currentTutorial.personal,
                  pobocka: entry
                }
              }
            };
          }))
      case 'poznamka':
        console.log('case poznamka');
        return(
        this.setState(function(prevState) {
          return {
            currentTutorial: {
              ...prevState.currentTutorial,
              personal: {
                ...prevState.currentTutorial.personal,
                "poznamka": entry
              }
            }
          };
        }))
      case 'profesia':
        console.log('case profesia');
        return(
        this.setState(function(prevState) {
          return {
            currentTutorial: {
              ...prevState.currentTutorial,
              personal: {
                ...prevState.currentTutorial.personal,
                "profesia": entry
              }
            }
          };
        }))
    }
  }

  onChangeGetId(e) {
      this.setState(function(prevState) {
      return {
        currentTutorial: {
          ...prevState.currentTutorial,
          id: this.props.location.state.id
        }
      };
    });
  }

  createProfile() {
    var data = this.state;
    console.log("saveProfile");
    TutorialDataService.create(data)
      .then(response => {
        console.log('profile created!!');
        this.setState({createMessage: "Success: The profile was created successfully!"});
      })
      .catch(e => {
        console.log(e);
        console.log('Profile creating failed!');
        this.setState({createMessage: "Error"});
        this.setState({showSpinner: false});
      });
  }

  onFileChange = (e) => {
    console.log('e.target.files[0]:',e.target.files[0])
    if (e.target && e.target.files[0]) {
      this.setState({fotka: e.target.files[0]})
      // this.state.formData.append('file',e.target.files[0])
    }
  }

  createLoading(){
    // console.log('entering createLoading...',this.state.createMessage, this.state.uploadMessage)
    if (this.state.fotka==="") {
      if (this.state.createMessage!=="" && this.state.npreukazToUpload===this.state.npreukaz_total) {
        return true
      }
      else return false
    }
    else{
      if (this.state.uploadMessage!=="" && this.state.createMessage!=="" && this.state.npreukazToUpload===this.state.npreukaz_total) {
        return true
      }
      else return false
    }
  }

  createSuccess(){
    // console.log('entering createSuccess...')
    if (this.state.fotka==="") {
      if (this.state.createMessage.slice(0,7)==="Success" && this.state.npreukazToUpload===this.state.npreukaz_total) {
        return true
      }
      else return false
    }
    else{
      if (this.state.createMessage.slice(0,7)==="Success" && this.state.uploadMessage.slice(0,7)==="Success" && this.state.npreukazToUpload===this.state.npreukaz_total) {
        return true
      }
      else return false
    }
  }

  

  onFileUpload = (i=0) => { 
    // // Create an object of formData 
    if (this.state.fotka && this.state.fotka!==""
      && this.state.currentTutorial.personal.meno && this.state.currentTutorial.personal.meno !== ""
      && this.state.currentTutorial.personal.priezvisko && this.state.currentTutorial.personal.priezvisko !== ""
      && this.state.currentTutorial.personal["dátum narodenia"] && this.state.currentTutorial.personal["dátum narodenia"] !== ""){
      const fileData = new FormData(); 

      const name = this.state.currentTutorial.personal.meno+this.state.currentTutorial.personal.priezvisko+this.state.currentTutorial.personal["dátum narodenia"]+"."+this.state.fotka.type.split("/")[1]
      fileData.append("name",name)
      fileData.append("file",this.state.fotka)

      console.log('ID_photo name:',name)

      TutorialDataService.upload(fileData)
        .then(res=> {
          console.log('res:',res)
          this.setState({uploadMessage: "Success: The photo was uploaded successfully!"})
        })
        .catch(err => {
          console.log('err:',err)
          this.setState({uploadMessage: "Error: The photo could not be uploaded!"})
        })
    }

    //count total number of licenses to upload: 
    let npreukaz_total = 0
    if (this.state.currentTutorial.personal.meno && this.state.currentTutorial.personal.meno !== ""
    && this.state.currentTutorial.personal.priezvisko && this.state.currentTutorial.personal.priezvisko !== ""
    && this.state.currentTutorial.personal["dátum narodenia"] && this.state.currentTutorial.personal["dátum narodenia"] !== ""){
    this.state.allCourses.map((course,index)=>{
      Array.from(Array(this.state.currentTutorial[course.course_nazov].npreukazState).keys()).map((i) => {
        if (["preukaz"+(i+1)] in this.state.currentTutorial[course.course_nazov] && this.state.currentTutorial[course.course_nazov]["preukaz"+(i+1)]) {
          npreukaz_total += 1
        }
      })
    })
    }
    this.setState({npreukaz_total: npreukaz_total})

    //upload the license cards
    if (this.state.currentTutorial.personal.meno && this.state.currentTutorial.personal.meno !== ""
    && this.state.currentTutorial.personal.priezvisko && this.state.currentTutorial.personal.priezvisko !== ""
    && this.state.currentTutorial.personal["dátum narodenia"] && this.state.currentTutorial.personal["dátum narodenia"] !== ""){
    this.state.allCourses.map((course,index)=>{
      Array.from(Array(this.state.currentTutorial[course.course_nazov].npreukazState).keys()).map((i) => {
        if (["preukaz"+(i+1)] in this.state.currentTutorial[course.course_nazov] && this.state.currentTutorial[course.course_nazov]["preukaz"+(i+1)]) {
          const fileScan = new FormData(); 
          console.log('nazov:',course.course_nazov)
          const idx = this.state.allCourses.findIndex(x => x.course_nazov===course.course_nazov)
          console.log('idx:',idx)
          const extension = (this.state.currentTutorial[course.course_nazov]["preukaz"+(i+1)].type)?this.state.currentTutorial[course.course_nazov]["preukaz"+(i+1)].type.split("/")[1]:"pdf"
          console.log('inferred extension:',extension)
          const nameScan = this.state.currentTutorial.personal.meno+this.state.currentTutorial.personal.priezvisko
                          +this.state.currentTutorial.personal["dátum narodenia"]
                          +"_"+this.state.allCourses[idx].course_alias
                          +["_preukaz"+(i+1)]
                          +"."
                          +extension
          fileScan.append("name",nameScan)
          console.log("name preukaz",i+1, " ",nameScan)
          fileScan.append("file",this.state.currentTutorial[course.course_nazov]["preukaz"+(i+1)])

          TutorialDataService.upload(fileScan)
            .then(res=> {
              const npreukaz_tmp = this.state.npreukazToUpload
              this.setState({npreukazToUpload: npreukaz_tmp+1})
              console.log('res:',res)
            })
            .catch(err => console.log('err:',err))
        }
      })
    })

  }
  }; 

  course_visible(nazov){
    console.log('course_visible...',this.state['show_'+nazov])
    // if (typeof this.state['show_'+nazov] !== 'undefined' || this.state['show_'+nazov]!==null){
      if (this.state['show_'+nazov]!=null){
      console.log('reversing...')
      this.setState({['show_'+nazov]:!this.state['show_'+nazov]})
    }
    else{
      this.setState({['show_'+nazov]:false})
    }
  }


  checkForPobocky(company_nazov){
    console.log('company nazov:',company_nazov)
    let pobocky = []
    const company = this.state.allCompanies.filter(item=>item.company_nazov === company_nazov)[0]
    if (!company || !company.company_kontakt_extra) {
      this.setState({'pobocky':[]})
      return []
    }else{
      const mesto = company.company_mesto
      if (mesto && mesto!=="") {pobocky.push(mesto)}
      console.log('checkForPobocky:',company,mesto,company.company_kontakt_extra)
      const extra_contacts = JSON.parse(company.company_kontakt_extra)
      console.log('checkForPobocky:',company,mesto,extra_contacts)
      extra_contacts.forEach(item=>{
        if (item.mesto && item.mesto!=="") {pobocky.push(item.mesto)}
      })
      this.setState({pobocky:pobocky})
      return pobocky
    }

  }

  render() {
    const { currentTutorial} = this.state;
    const createLoad = this.createLoading()
    const createSucc = this.createSuccess()
    // console.log('createLoading: ',createLoad)
    // console.log('employee being modified:',this.state.currentTutorial);
    // console.log('full state:',this.state);
    // console.log('sidebar:',this.state.sidebar)
    // console.log('createMessage, uploadMessage:',this.state.createMessage, this.state.uploadMessage)
    // console.log('fotka:',this.state.fotka)
    // console.log('checks:',this.state.checks)
    let value = 'apple'
    // if (window.sessionStorage.getItem("createPersonalProfileState")) console.log('stored state',JSON.parse(window.sessionStorage.getItem("createPersonalProfileState")))


    // if (this.state.currentTutorial["01.1a BOZP zamestnancov a vedúcich zamestnancov"]) console.log('#######evcislo:',this.state.currentTutorial["01.1a BOZP zamestnancov a vedúcich zamestnancov"].zakladne.evcislo)
    // if (this.state.currentTutorial["01.1a BOZP zamestnancov a vedúcich zamestnancov"]) console.log('#######vydanie:',this.state.currentTutorial["01.1a BOZP zamestnancov a vedúcich zamestnancov"].zakladne.vydanie)
    // const check = this.final_checks()
    // console.log('checks:',check)
    // console.log('this.ref:',this.ref)


    

    return (
     
    <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0, borderTop: 0}}>

      <Row className="fluid" style={{height: '100%',margin: 0,position:"fixed"}}>
 
        <Col xs={3} className="bg-dark border-right border-secondary my_scroll" style={{paddingBottom:100,paddingRight:0,paddingLeft:0}}>
          <br/>
          <Nav variant="tabs" style={{paddingLeft: 0}} defaultActiveKey="link-1">
            <Nav.Item>
              <Nav.Link eventKey="link-1" onClick={()=> this.setState({...this.state,sidebar: "caste"})}>Časté</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2" onClick={()=> this.setState({...this.state,sidebar: "vsetky"})}>Všetky</Nav.Link>
            </Nav.Item>
          </Nav>
          <br/>

          {"allCourses" in this.state ? (
            this.state.allCourses.sort((a, b) => a.course_nazov > b.course_nazov ? 1:-1).map(
              (course) => (
                (this.state.sidebar==="caste") ? (
                  course.course_caste ? <SideBarItem element_link={document.getElementById(course.course_nazov)} data_column = {document.getElementById("data_column")} ref_link = {this.ref} title={course.course_nazov} profession={course.course_nazov} showCourses={this.state.showCourses} changeCourses={this.changeCourses_short}/>: null
                ) : (
                  <SideBarItem ref_link = {this.ref} title={course.course_nazov} profession={course.course_nazov} showCourses={this.state.showCourses} changeCourses={this.changeCourses_short}/>
                ) 
              )
            )
           ) : console.log('not mounted...')}
        </Col>

        <Col id="data_column" xs={7} style={{paddingTop: 15, paddingLeft: 40, paddingRight: 40,marginBottom:0,paddingBottom:"100px"}} className="my_scroll">
          <div className="row w-100 mx-auto justify-content-center">
            <h4 className = "text-center" style={{color: this.color,width:"90%"}}>Základné informácie</h4>
            <div className="div-right">
              {/* <div className = ""> */}
                <label style={{color:"white", marginRight:"1em"}}>Aktívny profil:</label>
                <Switch
                  className="align-middle"
                  style={{marginRight: "3em",}}
                  checked={this.state.currentTutorial.active}
                  handleColor="white"
                  offColor="grey"
                  onChange={() => {
                    this.setState(function(prevState) {
                      return {
                        currentTutorial: {
                          ...prevState.currentTutorial,
                          active: !this.state.currentTutorial.active
                        }
                      };
                    });
                  }}
                  onColor="rgb(76, 217, 100)"
                />
              {/* </div> */}
              
              <div className="btn btn-secondary align-middle" onClick={()=>this.preserveState()}>Firmy</div>
            </div>
          </div>
          <br/>
          <Row>



              <div className="form-group col-lg-4 ">
                <label htmlFor="Titul" style={{color: "white"}}>Titul</label>
                <input
                  type="text"
                  className="form-control"
                  id="titul"
                  // required
                  value={this.state.currentTutorial.personal.titul}
                  onChange={(e) => this.onChangePersonal(e,'titul')}
                  name="titul"
                />
              </div>

              <div className="form-group col-lg-4 required">
                <label htmlFor="Example" style={{color: "white"}}>Meno</label>
                  <Autocomplete_example 
                    items = {this.state.database?this.state.database.filter(item=>item.table_name === 'personal_details')[0]['data']:[]} 
                    what = 'meno'
                    onChange = {this.onChangePersonal}
                    final_checks_clear = {this.final_checks_clear}
                    whatOnChange = 'meno'
                    whatOnClear = 'error_name'
                  />
                  {this.state.checks.error_name && this.state.checks.error_name!=="" && <p className="error">{this.state.checks.error_name}</p>}
              </div>

              {/* <div className="form-group col-lg-4 required">
                <label htmlFor="Meno" style={{color: "white"}}>Meno</label>
                <input
                  type="text"
                  className="form-control"
                  id="meno"
                  // required
                  value={this.state.currentTutorial.personal.meno}
                  onChange={(e) => {this.onChangePersonal(e,'meno');this.final_checks_clear("error_name")}}
                  name="meno"
                />
                {this.state.checks.error_name && this.state.checks.error_name!=="" && <p className="error">{this.state.checks.error_name}</p>}
              </div> */}

              <div className="form-group col-lg-4 required">  
                <label htmlFor="Example" style={{color: "white"}}>Priezvisko</label>
                  <Autocomplete_example 
                    items = {this.state.database?this.state.database.filter(item=>item.table_name === 'personal_details')[0]['data']:[]} 
                    what = 'priezvisko'
                    onChange = {this.onChangePersonal}
                    final_checks_clear = {this.final_checks_clear}
                    whatOnChange = 'priezvisko'
                    whatOnClear = 'error_surname'
                  />
                  {this.state.checks.error_surname && this.state.checks.error_surname!=="" && <p className="error">{this.state.checks.error_surname}</p>}
              </div>

              {/* <div className="form-group col-lg-4 required">
                <label htmlFor="Priezvisko" style={{color: "white"}}>Priezvisko</label>
                <input
                  type="text"
                  className="form-control"
                  id="priezvisko"
                  // required
                  value={this.state.currentTutorial.personal.priezvisko}
                  onChange={(e) => {this.onChangePersonal(e,'priezvisko');this.final_checks_clear("error_surname")}}
                  name="priezvisko"
                />
                {this.state.checks.error_surname && this.state.checks.error_surname!=="" && <p className="error">{this.state.checks.error_surname}</p>}
              </div> */}
          
              <div className="form-group col-lg-4 ">
                <label htmlFor="ulica" style={{color: "white"}}>Ulica</label>
                <input
                  type="text"
                  className="form-control"
                  id="ulica"
                  // required
                  value={this.state.currentTutorial.personal.ulica}
                  onChange={(e) => this.onChangePersonal(e,'ulica')}
                  name="ulica"
                />
              </div>
            
              <div className="form-group col-lg-4 ">
                <label htmlFor="mesto" style={{color: "white"}}>Mesto</label>
                <input
                  type="text"
                  className="form-control"
                  id="mesto"
                  // required
                  value={this.state.currentTutorial.personal.mesto}
                  onChange={(e) => this.onChangePersonal(e,'mesto')}
                  name="mesto"
                />
              </div>
            
              <div className="form-group col-lg-4 ">
                <label htmlFor="PSC" style={{color: "white"}}>PSČ</label>
                <input
                  type="text"
                  className="form-control"
                  id="psc"
                  // required
                  value={this.state.currentTutorial.personal.PSC}
                  onChange={(e) => this.onChangePersonal(e,'PSC')}
                  name="psc"
                />
              </div>

              <div className="form-group col-lg-4 ">
                <label htmlFor="email" style={{color: "white"}}>Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  // required
                  value={this.state.currentTutorial.personal.email}
                  onChange={(e) => this.onChangePersonal(e,'email')}
                  name="email"
                />
              </div>

              <div className="form-group col-lg-4 ">
                <label htmlFor="mobil" style={{color: "white"}}>Telefónne číslo</label>
                <input
                  type="text"
                  className="form-control"
                  id="mesto"
                  // required
                  value={this.state.currentTutorial.personal.mobil}
                  onChange={(e) => this.onChangePersonal(e,'mobil')}
                  name="mobil"
                />
              </div>

              <div className="form-group col-lg-4 required">
                <label htmlFor="dátum narodenia" style={{color: "white"}}>Dátum narodenia</label>
                <input
                  type="text"
                  className="form-control"
                  id="dátum narodenia"
                  // required
                  value={this.state.currentTutorial.personal['dátum narodenia']}
                  onChange={(e) => {this.onChangePersonal(e,'dátum narodenia');this.final_checks_clear("error_birth")}}
                  name="dátum narodenia"
                  placeholder="DD.MM.RRRR"
                />
                {this.state.checks.error_birth && this.state.checks.error_birth!=="" && <p className="error">{this.state.checks.error_birth}</p>}
              </div>



              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Firma</label>
                <select class="custom-select"
                  value={this.state.currentTutorial.personal['zamestnávateľ']}
                  // onChange={e=>this.setState(function(prevState) {return {currentCompany: {...prevState.currentCompany,pridelenie1: e.target.value}} })}
                  onChange={(e) => {this.onChangePersonal(e,'zamestnávateľ');this.checkForPobocky(e.target.value)}}
                >
                  <option selected>Vyber firmu</option>
                  {
                  this.state.allCompanies && this.state.allCompanies.sort((a, b) => a.company_nazov > b.company_nazov ? 1:-1).map(company=>                     
                      <option value={company.company_nazov}>{company.company_nazov}</option>
                  )
                  }
                </select>
              </div>

              <div className="form-group col-lg-4 ">
                <label style={{color: "white"}}>Pobočka</label>
                <select class="custom-select"
                  disabled={(this.state.pobocky && this.state.pobocky.length>0)?false:true}
                  value={this.state.currentTutorial.personal.pobocka}
                  onChange={(e) => {this.onChangePersonal(e,'pobocka')}}
                >
                  <option selected>Vyber pobočku</option>
                  {this.state.pobocky && this.state.pobocky.sort((a,b)=>a.localeCompare(b,'sk')).map(pobocka=>
                    <option
                      value={pobocka}
                    >
                      {pobocka}
                    </option>)}
                </select>
              </div>

              <div className="form-group col-lg-4 ">
                <label htmlFor="poznamka" style={{color: "white"}}>Poznámka</label>
                <input
                  type="text"
                  className="form-control"
                  id="poznamka"
                  // required
                  value={this.state.currentTutorial.personal.poznamka}
                  onChange={(e) => this.onChangePersonal(e,'poznamka')}
                  name="poznamka"
                />
              </div>

              <div className="form-group col-lg-4 ">
                <label htmlFor="profesia" style={{color: "white"}}>Profesia</label>
                <input
                  type="text"
                  className="form-control"
                  id="profesia"
                  // required
                  value={this.state.currentTutorial.personal.profesia}
                  onChange={(e) => this.onChangePersonal(e,'profesia')}
                  name="profesia"
                />
              </div>


              {/* <form method="post" enctype="multipart/form-data" action="/upload"> */}
              <div className="form-group round col-lg-4" style ={{ marginTop: 0}}>
                <label htmlFor="validatedCustomFile" style={{color: "white"}}>Fotka</label>
                <div className="custom-file">
                  <input  type="file" className="custom-file-input" id="validatedCustomFile" accept="image/png, image/jpeg, image/jpg" onChange={(e)=>this.onFileChange(e)}/>
                  <label className="custom-file-label" style={{marginTop:0,marginLeft:0}} for="validatedCustomFile">{(this.state.fotka==="") ? "Vyber súbor...": this.state.fotka.name}</label>
                </div>
                {/* <div className="input-group-append">
                  <button style={{marginTop:30, marginBottom:17}} className="btn btn-secondary" type="button" onClick={this.onFileUpload}>Nahrať</button>
                </div> */}
                <div className="invalid-feedback">Example invalid custom file feedback</div>
              </div>
              {/* </form> */}


            </Row>
              
            <Row>
            {"allCourses" in this.state ? (
              this.state.allCourses.map(
                (course) => (
                  this.state.showCourses[course.course_nazov] ? 
                    <>  
                      <hr/>
                      <div id={course.course_nazov} ref={this.ref} className="row w-100 mx-auto justify-content-center">
                        <h4  style={{color: this.color,width:"90%",textAlign:"center"}}>
                          {course.course_nazov}                         
                        </h4>
                        <div className = "div-right" style={{padding: "0"}}>
                        <label style={{color:"white", marginRight:"1em"}}>Aktívny kurz:</label>
                        <Switch
                          className="align-middle"
                          style={{marginRight: "3em",}}
                          checked={this.state.currentTutorial[course.course_nazov].active}
                          handleColor="white"
                          offColor="grey"
                          onChange={() => {
                            this.setState(function(prevState) {
                              return {
                                currentTutorial: {
                                  ...prevState.currentTutorial,
                                  [course.course_nazov]: {
                                    ...prevState.currentTutorial[course.course_nazov],
                                    active: !this.state.currentTutorial[course.course_nazov].active
                                  }
                                }
                              };
                            });
                          }}
                          onColor="rgb(76, 217, 100)"
                        />
                          <button className=" btn btn-outline-secondary div-right" style={{borderWidth: "2px"}} onClick ={()=>{this.course_visible(course.course_nazov)}}>
                          {(this.state['show_'+course.course_nazov]!=null && this.state['show_'+course.course_nazov]===false)?<FaChevronDown style={{fontSize: "12px", color: "white", cursor: "pointer"}}/>:<FaChevronUp style={{fontSize: "12px", color: "white", cursor: "pointer"}}/>}
                          </button>
                        </div>
                      </div>

                      {course.course_ZS ? 
                        (this.state['show_'+course.course_nazov]!=null && this.state['show_'+course.course_nazov]===false)?null:<ZS 
                          onChangePreukazScan={this.onChangePreukazScan}
                          isDate={this.isDate}
                          final_checks_clear={this.final_checks_clear}
                          checks={this.state.checks} 
                          course={course}
                          currentTutorial = {this.state.currentTutorial} 
                          onChangeUniversal = {this.onChangeUniversal}
                          handleKeyDown = {this.handleKeyDown}
                          handlePaste = {this.handlePaste}
                          handleDelete = {this.handleDelete}
                          npreukazTracer = {this.npreukazTracer}
                          noPreukazAllowed = {this.state.noPreukazAllowed}
                        /> : null
                      }
                      <span>&nbsp;&nbsp;</span>
                      {(course.course_OS && this.state.currentTutorial[course.course_nazov].npreukazState===0 && this.state.noPreukazAllowed.includes(course.course_alias)) ? 
                                                (this.state['show_'+course.course_nazov]!=null && this.state['show_'+course.course_nazov]===false)?null:<OS 
                          final_checks_clear={this.final_checks_clear}
                          nazov={course.course_nazov} 
                          currentTutorial = {this.state.currentTutorial} 
                          onChangeUniversal = {this.onChangeUniversal} 
                          handleKeyDown = {this.handleKeyDown}
                          handlePaste = {this.handlePaste}
                          handleDelete = {this.handleDelete}
                          npreukaz = {this.state.currentTutorial[course.course_nazov].npreukazState}
                        /> : null
                      }
                      {(course.course_AOP && this.state.currentTutorial[course.course_nazov].npreukazState===0 && this.state.noPreukazAllowed.includes(course.course_alias)) ? 
                                                (this.state['show_'+course.course_nazov]!=null && this.state['show_'+course.course_nazov]===false)?null:<AOP 
                          final_checks_clear={this.final_checks_clear}
                          nazov={course.course_nazov} 
                          currentTutorial = {this.state.currentTutorial} 
                          onChangeUniversal = {this.onChangeUniversal} 
                          handleKeyDown = {this.handleKeyDown}
                          handlePaste = {this.handlePaste}
                          handleDelete = {this.handleDelete}
                          npreukaz = {this.state.currentTutorial[course.course_nazov].npreukazState}
                      /> : null
                      }
                    </>
                  : null
                )
              )
            ) : console.log('some problems..')}
          </Row> 

          <div className="row w-75 mx-auto justify-content-center" style={{marginBottom:30}}>
            <button 
              disabled = {(this.state.database)?false:true}
              onClick={ () => {
                                    this.setState({
                                      showSpinner: true}); 
                                      if (this.final_checks()===false) {console.log('some problem in fill-up');this.setState({showDataCheckPopup: true})}else{this.createProfile();this.onFileUpload()}
                                    }}
                    className="btn btn-success justify-content-right">
              Uložiť
              {(this.state.showSpinner===true || !this.state.database) ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:5, width: "1.2rem", height: "1.2rem",}}></span>:null}
            </button>
            {(window.sessionStorage.getItem("createCourseState")) ? 
              <button style = {{marginLeft:10}} onClick={ () => window.location.href="/courses"}
                    className="btn btn-secondary justify-content-right">              
                Späť
            </button> : 
            <button style = {{marginLeft:10}} onClick={ () => window.location.href="/profile"}
                    className="btn btn-secondary justify-content-right">              
                Zahodiť zmeny
            </button>}
          </div>

          <SweetAlert 
            // show={this.state.createMessage!=="" }
            show={createLoad}
            
            type={(createSucc===true) ? "success" : "danger" }
            title={(createSucc===true) ? "Hotovo!" : "Pozor!" } 
            onConfirm={() => {
              this.setState({showUpdatePopup: false})
              this.setState({createMessage: "",uploadMessage: ""})
              this.setState({showSpinner: false});

              if (window.sessionStorage.getItem("createCourseState")) window.location.href='/courses'; else window.location.href='/profile';
            }
            }
            onCancel={()=>{
              this.setState({showUpdatePopup: false})
              this.setState({createMessage: "", uploadMessage: ""})
              this.setState({showSpinner: false});
              window.location.href='/profile'
            }}
            >
            {(createSucc===true)? "Profil úspešne vytvorený!" : "Pri vytváraní profilu sa vyskytla chyba!"}
          </SweetAlert>

          <SweetAlert 
            show={this.state.showDataCheckPopup===true}
            type="danger"
            title="Pozor!"
            onConfirm={() => {
              this.setState({showDataCheckPopup: false})
              this.setState({showSpinner: false});
            }
            }
            onCancel={()=>{
              this.setState({showDataCheckPopup: false})
              this.setState({showSpinner: false});
            }}
            >
            Skontrolujte, či sú vyplnené všetky povinné polia(*), a či majú časové dáta správny formát. 
          </SweetAlert>

          <SweetAlert 
            show={this.state.showPersonDefinedPopup===true}
            type="danger"
            title="Pozor!"
            onConfirm={() => {
              this.setState({showPersonDefinedPopup: false})
              this.setState({showSpinner: false});
            }
            }
            onCancel={()=>{
              this.setState({showPersonDefinedPopup: false})
              this.setState({showSpinner: false});
            }}
            >
            Profil s daným menom, priezviskom a dátumom narodenia sa už v databáze nachádza.
          </SweetAlert>

          <SweetAlert 
            show={this.state.showDeleteCoursePopup===true}
            showCloseButton
            type="danger"
            title="Pozor!"
            onConfirm={() => {
              this.setState({showDeleteCoursePopup: false})
              this.changeCourses_short_delete()
            }
            }
            onCancel={()=>{
              this.setState({showDeleteCoursePopup: false})
            }}
            >
            Naozaj chcete odstrániť kurz z aktuálneho profilu? Predvyplnené dáta budú vymazané!
          </SweetAlert>

          

        </Col>
        <Col xs={2}>
        </Col>
      </Row>
    </div>
    )
  }
}